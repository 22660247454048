import React, { useState, useEffect, useCallback } from "react";
import { connect } from 'react-redux';
import axios from "axios";
import { Table, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import Slider from '@mui/material/Slider';

const marks = [
    {
        value: 0,
        label: '0%',
    },
    {
        value: 50,
        label: '50%',
    },
    {
        value: 100,
        label: '100%',
    },
];

const PantallaPreguntas3Meses = (props) => {
    const [data, setData] = useState([]);
    const [changes, setChanges] = useState([0, 100]);
    const [slider, setSlider] = useState([0, 100]);

    const peticionAxios = useCallback(() => {
        let formData = new FormData();
        formData.append('pantalla', 7);
        formData.append('clienteid', props.loggedstate.vars.clienteid);
        formData.append('usuario', props.loggedstate.login.user);
        formData.append('permiso', props.loggedstate.login.permiso);
        formData.append('region', props.loggedstate.vars.region);
        formData.append('mes', props.loggedstate.vars.mes);
        formData.append('week', props.loggedstate.vars.week);
        formData.append('startdate', props.loggedstate.vars.startdate);
        formData.append('minimo', slider[0]);
        formData.append('maximo', slider[1]);
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'preguntas3meses.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                setData(data);
            })
            .catch(function (response) {
                // Handle error
            });
    }, [props.loggedstate, slider]);

    useEffect(() => {
        peticionAxios();
    }, [peticionAxios]);

    const Construir = useCallback(() => {
        const getComponents = (color) => Array.from({ length: 3 }, (_, i) => Math.floor(color / 16 ** (2 * i) % 16 ** 2));

        const interpolate = (arr1, arr2, percent) => arr1.map((v, index) => Math.floor(v + (arr2[index] - v) * (percent / 100)));

        function colors(value) {
            const StartColor = 0xFD665F; // Red
            const MiddleColor = 0xFFCE34; // Yellow
            const EndColor = 0x65B581; // Green

            let [start, end, v] = value < 50
                ? [StartColor, MiddleColor, value * 2]
                : [MiddleColor, EndColor, (value - 50) * 2];

            let interpoled = interpolate(getComponents(start), getComponents(end), v);

            return interpoled.reduce((n, component, index) => n + component * (16 ** (index * 2)), 0).toString(16).padStart(6, '0');
        }

        let tablas = [];
        data.forEach((region, regionIndex) => {
            Object.keys(region).forEach((key, index) => {
                tablas.push(
                    <Tr key={`tit-${regionIndex}-${index}`} className="campo_tit2">
                        <Th>{key}</Th>
                        <Th>Último mes</Th>
                        <Th>Un mes anterior</Th>
                        <Th>Dos meses anteriores</Th>
                    </Tr>
                );
                let content = region[key];
                Object.keys(content).forEach((contentKey, contentIndex) => {
                    tablas.push(
                        <Tr key={`content-${regionIndex}-${index}-${contentIndex}`}>
                            <Td>{contentKey}</Td>
                            <Td style={{ backgroundColor: "#" + colors(content[contentKey].total) }}>
                                {content[contentKey].total}%
                            </Td>
                            <Td style={{ backgroundColor: "#" + colors(content[contentKey].total2) }}>
                                {content[contentKey].total2}%
                            </Td>
                            <Td style={{ backgroundColor: "#" + colors(content[contentKey].total3) }}>
                                {content[contentKey].total3}%
                            </Td>
                        </Tr>
                    );
                });
            });
        });
        return tablas;
    }, [data]);

    const handleChange = (event, value) => setChanges(value);
    const handleRange = (event, value) => setSlider(value);

    return (
        <div id="pantalla7b" className="contenedorgrafica">
            {/*<div id='rango'>
                <Slider
                    value={changes}
                    onChange={handleChange}
                    onChangeCommitted={handleRange}
                    step={10}
                    marks={marks}
                />
            </div>*/}
            <div id="cont_interno">
                <Table className="tabla_resultados">
                    <Tbody>
                        {Construir()}
                    </Tbody>
                </Table>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    loggedstate: state
});

export default connect(mapStateToProps)(PantallaPreguntas3Meses);