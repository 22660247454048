import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

const style = {
    flex: 1
};

const PantallaTiendas3Meses = (props) => {
    const [data, setData] = useState([]);
    const [labels, setLabels] = useState([]);
    const [pagina, setPagina] = useState(1);
    const [paginas, setPaginas] = useState(1);
    const [porpagina, setPorpagina] = useState(10);
    const [buttonLabel, setButtonLabel] = useState("Crear Excel");
    const [fileUrl, setFileUrl] = useState(null);

    const Pager = () => {
        let paginasCount = parseInt(paginas) - 1;
        let paginaCount = parseInt(pagina);
        let mostrar = 5;
        let margen = 2;
        var botones = [];
        if (paginasCount < mostrar) {
            mostrar = paginasCount;
        }
        if (paginasCount > 1) {
            if (paginaCount <= margen) {
                let pagtemp = paginaCount - margen;
                if (pagtemp < 1) {
                    pagtemp = 1;
                }
                for (var i = pagtemp; i < pagtemp + mostrar && i <= paginasCount; i++) {
                    botones.push(<button data-ira={i} onClick={(e) => ira(e)} className={(i == paginaCount) ? ('activo') : (undefined)} type="button" key={i}> {i} </button>);
                }
            } else {
                for (var i = paginaCount - margen; i < paginaCount + mostrar - margen && i <= paginasCount; i++) {
                    botones.push(<button data-ira={i} onClick={(e) => ira(e)} className={(i == paginaCount) ? ('activo') : (undefined)} type="button" key={i}> {i} </button>);
                }
            }
            return (
                <div id="pager">
                    <button data-ira='1' onClick={(e) => ira(e)} className={('1' == paginaCount) ? ('activo') : (undefined)} type="button" key="inicio"> |{'<'} </button>
                    {botones}
                    <button data-ira={paginasCount} onClick={(e) => ira(e)} className={(paginasCount == paginaCount) ? ('activo') : (undefined)} type="button" key='fin'> {'>'}| </button>
                </div>
            )
        } else {
            return '';
        }
    }

    const ira = (e) => {
        let irapagina = e.target.dataset.ira;
        setPagina(irapagina);
    }

    const peticionAxios = () => {
        //traer meses
        let formData = new FormData();
        formData.append('pantalla', 2);
        formData.append('clienteid', props.loggedstate.vars.clienteid);
        formData.append('usuario', props.loggedstate.login.user);
        formData.append('permiso', props.loggedstate.login.permiso);
        formData.append('region', props.loggedstate.vars.region);
        formData.append('mes', props.loggedstate.vars.mes);
        formData.append('week', props.loggedstate.vars.week);
        formData.append('startdate', props.loggedstate.vars.startdate);
        formData.append('pagina', pagina);
        let generales;
        let datos = [];
        let labels = [];
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + 'tiendas3meses.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                datos = data.resultados_tiendas;
                setPaginas(datos.paginas);
                setPorpagina(datos.porpagina);
                setData(datos.resultados);
            })
            .catch(function (response) {
                //console.log(response[0]);
            });
    }

    useEffect(() => {
        peticionAxios();
    }, [pagina, props.loggedstate.vars.region]);

    const getComponents = (color) => Array.from({ length: 3 }, (_, i) => Math.floor(color / 16 ** (2 * i) % 16 ** 2))

    // interpolate arrays by component
    const interpolate = (arr1, arr2, percent) => arr1.map((v, index) => Math.floor(v + (arr2[index] - v) * (percent / 100)))

    function colors(value) {
        const StartColor = 0xFD665F; // Red
        const MiddleColor = 0xFFCE34; // Yellow
        const EndColor = 0x65B581; // Green

        let [start, end, v] = value < 50
            ? [StartColor, MiddleColor, value * 2]
            : [MiddleColor, EndColor, (value - 50) * 2]

        let interpoled = interpolate(getComponents(start), getComponents(end), v)

        return interpoled.reduce((n, component, index) => n + component * (16 ** (index * 2)), 0).toString(16).padStart(6, '0')
    }


    return (
        <div id="pantalla2b" className="contenedorgrafica">
            {data.length > 0 ? (
                <Table>
                    <Thead>
                        <Tr>
                            <Th>Tienda</Th>
                            <Th>Último mes</Th>
                            <Th>Un mes anterior</Th>
                            <Th>Dos meses anteriores</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data.map((tienda, index) => {
                            return (
                                <Tr key={index}>
                                    <Td>{tienda.name}</Td>
                                    <Td style={{ backgroundColor: "#" + colors(tienda.value) }}>{tienda.value}%</Td>
                                    <Td style={{ backgroundColor: "#" + colors(tienda.value2) }}>{tienda.value2}%</Td>
                                    <Td style={{ backgroundColor: "#" + colors(tienda.value3) }}>{tienda.value3}%</Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            ) : (
                <p>No hay datos</p>
            )}

            <Pager />
        </div>
    );
}

const mapStateToProps = state => ({
    loggedstate: state
})

export default connect(mapStateToProps)(PantallaTiendas3Meses);